import React from 'react';
import './feature.css';

const Feature = ({ title, text }) => (
  <div className="RBCSW__features-container__feature">
    <div className="RBCSW__features-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div />
    <div className="RBCSW__features-container__feature-text">
      <p onClick={() => window.open(text)}>{text} </p>
    </div>
  </div>
);

export default Feature;