import React from 'react';
// import possibilityImage from '../../assets/possibility.png';
import possibilityImage from '../../assets/Rocket.svg';
import './possibility.css';

const Possibility = () => {
  return (
    <div className='RBCSW__possibility section__padding' id="possibility">
      <div className="RBCSW__possibility-image ">
        <img src={possibilityImage} alt="possibility" />
      </div>
      <div className='RBCSW__possibility-content'>
        <h4>Request Early Access to Get Started</h4>
        <h1 className="gradient__text">The possibilities are beyong your imagination</h1>
        <p>Contact Rebecca Software Consulting firm, we will help you even for small tasks</p>
        <h4>Request Early Access to Get Started</h4>
      </div>
    </div>
  )
}

export default Possibility