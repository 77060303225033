import twitter from '../assets/twitter.svg'
// import google from '../assets/google.svg'
import linkedin from '../assets/linkedin.svg'
import instagram from '../assets/instagram.svg'
// import github from '../assets/github.svg'
import facebook from '../assets/facebook.svg'

// import {google, facebook, twitter, github, linkedin} from '../assets';
export const featuresData = [
  {
    title: 'Exploring Rockets',
    text: 'https://spacecoastlaunches.com/rockets/',
  }, 
  {
    title: 'SpaceX Rockets, Starship, Human Spaceflight... ',
    text: 'https://www.spacex.com/vehicles/falcon-9/',
  },  
  {
    title: 'SpaceX Mission',
    text: 'https://www.spacex.com/mission/',
  },  
  {
    title: 'SpaceX Launches',
    text: 'https://www.spacex.com/launches/',
  },
];
 
export const socialMedia = [
  {
    id: "twitter",
    icon:twitter,
    link: "https://www.twitter.com/",
  },  
  {
    id: "facebook",
    icon:facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "linkedin",
    icon:linkedin,
    link: "https://www.linkedin.com/",
  },
  // {
  //   id: "github",
  //   icon:github,
  //   link: "https://www.github.com/",
  // },
  {
    id: "instagram",
    icon:instagram,
    link: "https://www.instagram.com/",
  },
]
