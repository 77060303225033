import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';
import { featuresData } from '../../constants';


const Features = () => (
  <div className="RBCSW__features section__padding" id="features">
    <div className="RBCSW__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen.</h1>
      <p Request Early Access to Get Started/>
    </div>
    <div></div>
    <div className="RBCSW__features-container">
     {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} 
        key={item.title + index} 
        />
      ))} 
    </div> 
  </div>
);

export default Features;