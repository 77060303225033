import React from 'react';
import RBCSWLogo from '../../assets/OutReaching.Space.svg';
import './footer.css';
import {socialMedia} from "../../constants";

const Footer = () => (
  <div className="RBCSW__footer section__padding">
    <div className="RBCSW__footer-heading">
      <h1 className="gradient__text">Do you want to step in to the future before others</h1>
    </div>

    <div className="RBCSW__footer-btn">
      <p>Request Early Access</p>
    </div>

    <div className="RBCSW__footer-links">
      <div className="RBCSW__footer-links_logo">
        <img src={RBCSWLogo} alt="RBCSW_logo" />
        <p>RebeccaSoftware.space <br /> All Rights Reserved</p>
      </div>

      <div className="RBCSW__footer-links_div">
        <h4>Get in touch</h4>
        <p>Rebecca Software Consulting</p>
        <p>Info@RebeccaSoftware.space</p>
      </div>
    </div>

    <div className='RBCSW__footer-social'>
        {socialMedia.map((social, index) => (
          <img 
            src={social.icon}
            alt={social.id}
            className="hover:text-blue-300"
            onClick={() => window.open(social.link)}
          />
        ))}
    </div>

    <div className="RBCSW__footer-copyright">
      <p>@2022 Rebecca Software Consulting, LLC. All rights reserved.</p>
    </div> 
  </div>
);

export default Footer;