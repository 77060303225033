import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="RBCSW__header section__padding" id="home">
    <div className="RBCSW__header-content">
      <h1 className="gradient__text">This site is inspired by my beloved ones departed from this planet recently... They are transiting through constellations now...</h1>
      <p>The site is dedicated to friends and families. We love you all!!!</p>
      <div className="RBCSW__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button>
      </div>

      <div className="RBCSW__header-content__people">
        <img src={people} alt="people"/>
        <p> 728 people accessed to our site in past 24 hours </p>
      </div>
    </div>
    <div className="RBCSW__header-image">
        <img src={ai} alt="ai"/>
    </div>
   </div>
  );


export default Header