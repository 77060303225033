import React, { useState } from 'react';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import logo from '../../assets/OutReaching.Space.svg';
import './navbar.css';

const Menu = () =>(
  <>
  <p><a href="#home">Home</a></p>
  <p><a href="#wrbcsw">Visioning</a></p>
  <p><a href="#possibility">Approching</a></p>
  <p><a href="#features">Engineering</a></p>
  <p><a href="#blog">Outreaching</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

// BEN -> Block Element Modifier
  return (
    <div className="RBCSW__navbar">
      <div className="RBCSW__navbar-links">
        <div className='RBCSW__navbar-links-logo'>
          <img  src = {logo} alt={"logo"} />
        </div>
        <div className='RBCSW__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='RBCSW__navbar-sign'>
        <p>Sign in 
          <button type="button">Sign up</button>
        </p>
      </div>
      <div className="RBCSW__navbar-menu">
          {toggleMenu
            ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
            {toggleMenu && (
              <div className="RBCSW__navbar-menu_container scale-up-center">
              <div className="RBCSW__navbar-menu_container-links">
                <Menu />
              </div>
              <div className="RBCSW__navbar-menu_container-links-sign">
                <p>Sign in</p>
                <button type="button">Sign up</button>
              </div>
            </div>
            ) }            
        </div>
    </div> 
  );
};

export default Navbar;