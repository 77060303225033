import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatRBCSW.css'

const WhatRBCSW = () => (
  <div className="RBCSW__whatRBCSW section__margin" id="wrbcsw">
    <div className="RBCSW__whatRBCSW-feature">
      <Feature title="What is Outreaching.Space" text="We so opinion friends me message as delight. Whole front do of plate heard oh ought. His defective nor convinced residence own. Connection has put impossible own apartments boisterous." />
    </div>
    <div className="RBCSW__whatRBCSW-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Explore the Library</p>
    </div>
    <div className="RBCSW__whatRBCSW-container">
      <Feature title="Chatbots" text="We so opinion friends me message as delight. Whole front do of plate heard oh ought." />
      <Feature title="Knowledgebase" text="At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by. " />
      <Feature title="Education" text="As put impossible own apartments boisterous." />
    </div>
  </div>
);

export default WhatRBCSW;